import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  getValidatorErrorMessage(validatorName: string, valueMoney: boolean, validatorValue?: any) {
    const messageValidation = new Map([
      ['required', `Campo requerido`],
      ['email', `E-mail inválido`],
      ['checkEmailValidator', `E-mail inválido`],
      ['invalidEmailAddress', `Endereço de e-mail inválido`],
      ['minlength', `Tamanho mínimo ${validatorValue.requiredLength}`],
      ['maxlength', `Tamanho máximo ${validatorValue.requiredLength}`],
      ['pattern', `Campo inválido`],
      ['checkCPF', `CPF inválido`],
      ['checkCNPJ', `CNPJ inválido`],
      ['checkSelect', `Campo requerido`],
      ['min', `Valor mínimo inferior a ${valueMoney ? new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(validatorValue.min) : validatorValue.min}`],
      ['max', `Valor máximo superior a ${valueMoney ? new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(validatorValue.max) : validatorValue.max}`],
    ]);

    return messageValidation.get(validatorName);
  }

  static creditCardValidator(control: AbstractControl) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    if (control.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
      return null;
    } else {
      return { 'invalidCreditCard': true };
    }
  }

  static emailValidator(control: AbstractControl) {
    // RFC 2822 compliant regex
    if (control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
      return null;
    } else {
      return { 'invalidEmailAddress': true };
    }
  }

  static passwordValidator(control: AbstractControl) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
      return null;
    } else {
      return { 'invalidPassword': true };
    }
  }

  /**
   *
   * @param {AbstractControl} control
   * @returns
   * @memberof Validation
   */
  errorMessage(control: AbstractControl, valueMoney: boolean = false): any {
    if (control.errors) {
      for (let propertyName in control.errors) {
        if (control.errors.hasOwnProperty(propertyName) && control.touched) {
          return this.getValidatorErrorMessage(propertyName, valueMoney, control.errors[propertyName], );
        }
      }
    }

    return null;
  }

}
