import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CachingService } from 'src/app/shared/service/caching.service';
import { jwt } from 'src/app/shared/constants/jwt';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private path = environment.API_BASE_URL_CORE + '/authenticate';
  private pathOut = this.path + '/signOut';
  private pathReset = this.path + '/password/reset';

  constructor(
    private http: HttpClient, 
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private _c: CachingService) { }

  /**
     * Faz autenticação do usuário
     * @param {string} email
     * @param {string} password
     * @returns {Observable<any>}
     * @memberof LoginService
     */
  login(email: string, password: string) {
    const creds = {
      email: email,
      password: password
    };
    

    return this.http.post(this.path, creds)
      .pipe(
        map(
          (data: any) => {
            this._c.reset();
            localStorage.setItem(jwt.JWT_KEY, data.data.token);
            localStorage.setItem(jwt.THEME_MODE, 'light');
          }
        ),
        catchError(err => {
          return of([]);
      }),
      );
  }

  /**
     * Reseta senha do usuário
     * @param {string} email
     * @returns {Observable<any>}
     * @memberof LoginService
     */
  reset(email: string) {
    return this.http.post(this.pathReset, email);
  }

  /**
   * Remove autenticação do usuário
   * @memberof LoginService
   */
  logout(): void {
    localStorage.removeItem(jwt.JWT_KEY);
    this._c.reset();
    this.http.post(this.pathOut, {});
    this.router.navigate(['/']);
    this.permissionsService.flushPermissions();
  }
}
