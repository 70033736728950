import { 
  cisMenu,
  cisHome,
  cisBriefcase,
  cisAddressCard,
  cilX,
  cilCheckAlt,
  cisBell,
  cilBriefcase,
  cisSearch,
  cilAddressCard,
  cibCashapp,
  cilUser,
  cilLockLocked,
  cilInput,
  cilMagnifyingGlass,
  cisArrowThickLeft,
  cisCheckAlt,
  cilCalendar,
  cilAccountLogout,
  cilHome,
  cilDescription,
  cilCamera,
  cilCheckCircle,
  cilCar,
  cilNotes,
  cilTrash,
  cilCloudDownload,
  cidCloudUpload,
  cilLocationPin,
  cidLoopCircular,
  cilChevronDoubleRight,
  cidWarning,
  cisCheck,
  cisCheckDouble,
  cisArrowThickBottom,
  cisArrowThickTop,
  cilXCircle,
  cilBuilding
} from '@coreui/icons-pro';

export const iconSubset = {
  cisMenu,
  cisHome,
  cisBriefcase,
  cisAddressCard,
  cilX,
  cilCheckAlt,
  cisBell,
  cilBriefcase,
  cisSearch,
  cilAddressCard,
  cibCashapp,
  cilUser,
  cilLockLocked,
  cilInput,
  cilMagnifyingGlass,
  cisArrowThickLeft,
  cisCheckAlt,
  cilCalendar,
  cilAccountLogout,
  cilHome,
  cilDescription,
  cilCamera,
  cilCheckCircle,
  cilCar,
  cilNotes,
  cilTrash,
  cilCloudDownload,
  cidCloudUpload,
  cilLocationPin,
  cidLoopCircular,
  cilChevronDoubleRight,
  cidWarning,
  cisCheck,
  cisCheckDouble,
  cisArrowThickBottom,
  cisArrowThickTop,
  cilXCircle,
  cilBuilding
};

export enum IconSubset {  
  cisMenu = 'cisMenu',
  cisHome = 'cisHome',
  cisBriefcase = 'cisBriefcase',
  cisAddressCard = 'cisAddressCard',
  cilX = 'cilX',
  cilCheckAlt = 'cilCheckAlt',
  cisBell = 'cisBell',
  cilBriefcase = 'cilBriefcase',
  cisSearch = 'cisSearch',
  cilAddressCard = 'cilAddressCard',
  cibCashapp = 'cibCashapp',
  cilUser = 'cilUser',
  cilLockLocked = 'cilLockLocked',
  cilInput = 'cilInput',
  cilMagnifyingGlass = 'cilMagnifyingGlass',
  cisArrowThickLeft = 'cisArrowThickLeft',
  cisCheckAlt = 'cisCheckAlt',
  cilCalendar = 'cilCalendar',
  cilAccountLogout = 'cilAccountLogout',
  cilHome = 'cilHome',
  cilDescription = 'cilDescription',
  cilCamera = 'cilCamera',
  cilCheckCircle = 'cilCheckCircle',
  cilCar = 'cilCar',
  cilNotes = 'cilNotes',
  cilTrash = 'cilTrash',
  cilCloudDownload = 'cilCloudDownload',
  cidCloudUpload = 'cidCloudUpload',
  cilLocationPin = 'cilLocationPin',
  cidLoopCircular = 'cidLoopCircular',
  cilChevronDoubleRight = 'cilChevronDoubleRight',
  cidWarning = 'cidWarning',
  cisCheck = 'cisCheck',
  cisCheckDouble = 'cisCheckDouble',
  cisArrowThickBottom = 'cisArrowThickBottom',
  cisArrowThickTop = 'cisArrowThickTop',
  cilXCircle = 'cilXCircle',
  cilBuilding = 'cilBuilding'
}
