import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';

import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule, } from 'ngx-perfect-scrollbar';

// Import routing module
import { AppRoutingModule } from './app-routing.module';

// Import app component
import { AppComponent } from './app.component';

// Import containers
import { DefaultFooterComponent, DefaultLayoutComponent } from './containers';

// Import interceptors
import { InterceptorModule } from './shared/intercept/interceptor.module';

// Import Guards
import { AuthGuard } from './shared/guard/auth.guard';

// Import libs
import { ToastrModule } from 'ngx-toastr';

// Import Service
import { JwtService } from 'src/app/shared/service/jwt.service';
import { ConfirmationDialogService } from 'src/app/shared/component/confirmation-dialog/shared/confirmation-dialog.service';
import { ProgressDialogService } from 'src/app/shared/component/progress-dialog/shared/progress-dialog.service';
//import { WebCamModalService } from 'src/app/shared/component/webcam-modal/shared/webcam-modal.service';

//Permissions
import { NgxPermissionsModule } from 'ngx-permissions';

import { AvatarModule, BadgeModule, BreadcrumbModule, ButtonGroupModule, ButtonModule, CardModule, DropdownModule, FooterModule, FormModule, GridModule, HeaderModule, ListGroupModule, NavModule, ProgressModule, SharedModule, SidebarModule, TabsModule, UtilitiesModule, } from '@coreui/angular-pro';

import { IconModule, IconSetService } from '@coreui/icons-angular';
import { NgxSpinnerModule } from "ngx-spinner";
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { ConfirmationDialogComponent } from './shared/component/confirmation-dialog/confirmation-dialog.component';
import { ProgressDialogComponent } from './shared/component/progress-dialog/progress-dialog.component';
import { NgxsModule } from '@ngxs/store';
import { IgxBottomNavModule } from 'igniteui-angular';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

const APP_CONTAINERS = [
  DefaultFooterComponent,
  DefaultLayoutComponent
];

const maskConfig: Partial<IConfig> = {
  validation: false,
};

const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  decimal: ",",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: "."
};

@NgModule({
  declarations: [AppComponent, ...APP_CONTAINERS, ConfirmationDialogComponent, ProgressDialogComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AvatarModule,
    BreadcrumbModule,
    FooterModule,
    DropdownModule,
    GridModule,
    HeaderModule,
    IconModule,
    PerfectScrollbarModule,
    NavModule,
    ButtonModule,
    FormModule,
    UtilitiesModule,
    ButtonGroupModule,
    ReactiveFormsModule,
    SidebarModule,
    SharedModule,
    TabsModule,
    ListGroupModule,
    ProgressModule,
    BadgeModule,
    ListGroupModule,
    CardModule,
    HttpClientModule,
    InterceptorModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    NgxSpinnerModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    NgxsModule.forRoot([]),
    CurrencyMaskModule,
    IgxBottomNavModule
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
      //useClass: PathLocationStrategy,      
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    IconSetService,
    Title,
    AuthGuard,
    provideEnvironmentNgxMask(maskConfig),
    {
      provide: CURRENCY_MASK_CONFIG,
      useValue: CustomCurrencyMaskConfig
    },
    JwtService,
    ConfirmationDialogService,
    ProgressDialogService,
    //WebCamModalService
  ],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmationDialogComponent, ProgressDialogComponent]
})
export class AppModule {
}
