<igx-bottom-nav #tabs1>
  <igx-bottom-nav-item routerLinkActive #tap1="routerLinkActive"
  [selected]="tap1.isActive" #home>
    <igx-bottom-nav-header routerLink="/inicial">
        <svg cIcon name="cisHome" size="xl"></svg>
        <span igxBottomNavHeaderLabel *ngIf="home.selected">Home</span>
    </igx-bottom-nav-header>
  </igx-bottom-nav-item>
  <igx-bottom-nav-item routerLinkActive #tap2="routerLinkActive"
  [selected]="tap2.isActive" #menu>
    <!-- <igx-bottom-nav-header routerLink="/menu"> -->
      <igx-bottom-nav-header toggle="visible"
        cHeaderToggler
        [cSidebarToggle]="sidebarId">
      <svg cIcon name="cisMenu" size="xl"></svg>
      <span igxBottomNavHeaderLabel *ngIf="menu.selected">Menu</span>
    </igx-bottom-nav-header>
  </igx-bottom-nav-item>
  <igx-bottom-nav-item routerLinkActive #tap3="routerLinkActive"
  [selected]="tap3.isActive" #rot>
    <igx-bottom-nav-header routerLink="/roteiro">
      <svg cIcon name="cisBriefcase" size="xl"></svg>
      <span igxBottomNavHeaderLabel *ngIf="rot.selected">Roteiro</span>
    </igx-bottom-nav-header>
  </igx-bottom-nav-item>
  <igx-bottom-nav-item routerLinkActive #tap4="routerLinkActive"
  [selected]="tap4.isActive" #perfil>
    <igx-bottom-nav-header routerLink="/perfil">
      <svg cIcon name="cisAddressCard" size="xl"></svg>
      <span igxBottomNavHeaderLabel *ngIf="perfil.selected">Perfil</span>
    </igx-bottom-nav-header>
  </igx-bottom-nav-item>
</igx-bottom-nav>
