import { Component, OnInit } from '@angular/core';
import { INavData } from '@coreui/angular-pro';
import { INavDataRoles } from 'src/app/shared/interface/sidebar-nav-roles';
import { PermissionService } from 'src/app/shared/service/permission.service';
import { ClassToggleService } from '@coreui/angular-pro';
import { jwt } from 'src/app/shared/constants/jwt';

import { navItems } from './_nav';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  providers : [ PermissionService ]
})
export class DefaultLayoutComponent implements OnInit {

  public navItems: INavData [] = []

  public perfectScrollbarConfig = {
    suppressScrollX: true,
  };

  constructor(
    private permissionService: PermissionService,
    private permissionsService: NgxPermissionsService,
    private classToggler: ClassToggleService
  ) {}

  ngOnInit(): void {
    this.setTheme();
    
    if (Object.keys(this.permissionsService.getPermissions()).length === 0) {
      this.permissionService.getAll().subscribe(response => { 
        this.permissionsService.loadPermissions(response.data.map((role: any) => (role.role))) 
        this.navItems = this.montarNav(navItems, Object.keys(this.permissionsService.getPermissions()))
      })    
    } else {
      this.navItems = this.montarNav(navItems, Object.keys(this.permissionsService.getPermissions())) 
    }    
  }

  montarNav(navItems: INavDataRoles [], permissionList: Array<any>) {
    let viewNavItens: INavData [] = [];
    navItems.forEach(nav => {
      if (nav?.roles && nav.roles.length) {
        if (this.permissionService.hasRole(permissionList, nav.roles)) {
          if (nav?.children) {
            nav.children = this.montarNav(nav.children, permissionList)
          }
          viewNavItens.push(nav)
        }
      } else {
        if (nav?.children) {
          nav.children = this.montarNav(nav.children, permissionList)
        }
        viewNavItens.push(nav)
      }
    })
    return viewNavItens
  }

  /**
   * 
   * @returns
   * @memberof LoginComponent
   */
  setTheme() {
    let body = document.getElementById("principal");

    if (!body?.classList.contains("dark-theme")) {
      this.classToggler.toggle('body', 'dark-theme');
    }
  }
}
