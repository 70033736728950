import { Component, Input } from '@angular/core';
import { FooterComponent } from '@coreui/angular-pro';

@Component({
  selector: 'app-default-footer',
  templateUrl: './default-footer.component.html',
  styleUrls: ['./default-footer.component.scss'],
})
export class DefaultFooterComponent extends FooterComponent {

  @Input() sidebarId: string = "sidebar1";

  constructor() {
    super();
  }

}
