// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  FileStorageURL: 'https://files.sdssolucoes.com.br/',
  AreaAutos: 'AREA_AUTOS',
  BASE_URL: 'https://app-v2.hmle.sdssolucoes.com.br/',
  API_BASE_URL_CORE: 'https://api.hmle.sdssolucoes.com.br/core/v1',
  API_BASE_URL_REGULATION_COMMON: 'https://api.hmle.sdssolucoes.com.br/regcommon/v1',
  API_BASE_URL_REGULATION_AUTO: 'https://api.hmle.sdssolucoes.com.br/regauto/v1',
  API_BASE_URL_MOBILE: 'https://api.hmle.sdssolucoes.com.br/mobile/v1',

  COMPANY_ID: '5EE5ED92-8059-41D3-91C2-95A6D0D4C7C9',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
