<!--sidebar-->
<c-sidebar
  #sidebar1="cSidebar"
  class="d-print-none sidebar sidebar-fixed"
  id="sidebar1"
  visible
>
  <c-sidebar-brand
    [brandFull]="{
      src: 'assets/img/logotipo/logotipo_simbolo_branco.png',
      width: 46,
      height: 60,
      alt: 'SDS Logo'
    }"
    [brandNarrow]="{
      src: 'assets/img/logotipo/logotipo_simbolo_branco.png',
      width: 46,
      height: 60,
      alt: 'SDS Logo'
    }"
    [routerLink]="[]"
  >
  </c-sidebar-brand>

  <perfect-scrollbar [config]="perfectScrollbarConfig">
    <c-sidebar-nav [navItems]="navItems"dropdownMode="close"
  ></c-sidebar-nav>
  </perfect-scrollbar>

  <c-sidebar-toggler
    *ngIf="!sidebar1.narrow"
    toggle="unfoldable"
    cSidebarToggle="sidebar1"
  ></c-sidebar-toggler>
</c-sidebar>

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
  <!--app-body-->
  <div class="body flex-grow-1 px-3">
      <router-outlet>
        <ngx-spinner type="ball-scale-multiple">
          <p style="color: white" > Carregando... </p>
        </ngx-spinner>
      </router-outlet>
  </div>
  <app-default-footer></app-default-footer>
</div>
