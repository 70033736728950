import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ValidationService } from 'src/app/shared/service/validation.service';
import { LoginService } from '../shared/login.service';
import { ClassToggleService } from '@coreui/angular-pro';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [LoginService]
})
export class ResetPasswordComponent implements OnInit {
  
  //FORM GROUP
  public resetForm!: FormGroup;
  //FORM
  public emailControl!: AbstractControl;

  constructor(
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    private validationService: ValidationService,
    private router: Router,
    private classToggler: ClassToggleService
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.setTheme();
  }

  /**
     * Montagem do formulário
     * @memberof ResetPasswordComponent
     */
  buildForm(): void {
    this.resetForm = this.formBuilder.group({
      emailControl: ['', Validators.compose([Validators.required])],
    });

    this.emailControl = this.resetForm.controls['emailControl'];
  }

  /**
     * Tratamento de erros do form
     * @param {AbstractControl} control
     * @returns
     * @memberof ResetPasswordComponent
     */
  errorMessage(control: AbstractControl) {
    return this.validationService.errorMessage(control);
  }

  /**
     * Tratamento de erros do form
     * @param {AbstractControl} control
     * @returns
     * @memberof ResetPasswordComponent
     */
  onReset(): void {   
    this.loginService.reset(this.emailControl.value)
      .subscribe(
        () => {
          this.router.navigate(['/']);
        }
      );
  }

  /**
   * 
   * @returns
   * @memberof LoginComponent
   */
  setTheme() {
    let body = document.getElementById("principal");

    if (!body?.classList.contains("dark-theme")) {
      this.classToggler.toggle('body', 'dark-theme');
    }
  }
}
