<div class="fade show" style="padding-top: calc(40vh / 2); height: 100vh">
  <img src="./assets/img/logotipo/logo_sds_2022.png" alt="Logo" class="img-fluid mb-4">
  <c-row class="justify-content-center px-4 mt-2">
    <form [formGroup]="loginForm">
      <c-input-group class="mb-3" sizing="lg">
        <span cInputGroupText>
          <svg cIcon name="cilUser"></svg>
        </span>
        <input autoComplete="username" formControlName="emailControl" placeholder="Usuário" class="form-control"
          [ngClass]="{'is-invalid': errorMessage(emailControl) !== null}" />
        <div *ngIf="errorMessage(emailControl) !== null" class="invalid-feedback">
          {{ errorMessage(emailControl) }}
        </div>
      </c-input-group>
      <c-input-group class="mb-4" sizing="lg">
        <span cInputGroupText>
          <svg cIcon name="cilLockLocked"></svg>
        </span>
        <input autoComplete="current-password" formControlName="senhaControl" class="form-control" placeholder="Senha"
          type="password" [ngClass]="{'is-invalid': errorMessage(senhaControl) !== null}" />
        <div *ngIf="errorMessage(senhaControl) !== null" class="invalid-feedback">
          {{ errorMessage(senhaControl) }}
        </div>
      </c-input-group>
      <c-row>
        <c-col xs="12" class="d-grid gap-1">
          <button cButton color="primary" type="submit" size="lg" (click)="onLogin()" [loading]="loading" cLoadingButton
            [disabled]="loginForm.invalid">
            <svg cIcon name="cilInput" *ngIf="!loading"></svg>
            Login
          </button>
        </c-col>
      </c-row>
      <c-row>
        <c-col xs="12" class="d-grid gap-1">
          <button cButton class="px-0" color="link" size="lg" [routerLink]="['/reset-password']">
            Esqueceu sua senha?
          </button>
        </c-col>
      </c-row>
    </form>
  </c-row>
  <c-row class="justify-content-center mt-4 pt-4">
    <c-col class="color-version">
      <p class="text-center">
        Versão: {{ version }}
      </p>
    </c-col>
  </c-row>
</div>