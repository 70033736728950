import { INavDataRoles } from '../../shared/interface/sidebar-nav-roles';

export const navItems: INavDataRoles [] = [
  {
    name: 'Página Inicial',
    url: '/inicial',
    iconComponent: { name: 'cil-home' }
  },
  {
    name: 'Sair',
    url: '/login',
    iconComponent: { name: 'cil-account-logout' },
    roles: [ ]
  },
];