import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthGuard } from '../guard/auth.guard';
import { Router } from '@angular/router';

import { catchError, filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthGuard,
              private router: Router,
              private toastr: ToastrService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let authReq = request.clone({});
    const started = Date.now(); 

    if (this.auth.isAuthorized()) {
      authReq = this.addAuthToken(request);
    }

    return next.handle(authReq).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.url == (environment.API_BASE_URL_CORE + '/authenticate') && event.body.code < 0 ) {              
              this.toastr.info("Usuário e senha incorretos.");
            }
          }
        },
        error: (error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 400) {
              localStorage.clear();
              this.router.navigate(['/login']);
            } else if (error.status === 401 || error.status === 403) {
              localStorage.clear();
              this.router.navigate(['/login']);
            }
          }
        }
      }),
      // Log when response observable either completes or errors
      finalize(() => {
        // const elapsed = Date.now() - started;
        // const msg = `${request.method} "${request.urlWithParams}" in ${elapsed} ms.`;
        // console.log(msg);
      })
    );
  }

  /**
   * 
   * @param request {HttpRequest}
   * @returns {HttpRequest}
   */
  addAuthToken(request: HttpRequest<any>) {
    const token = this.auth.getAuthorization();

    if (!token) {
      return request;
    }

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
