import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from './../shared/login.service';
import { ValidationService } from '../../../shared/service/validation.service';
import { jwt } from 'src/app/shared/constants/jwt';
import { Router } from '@angular/router';
import packagejson from '../../../../../package.json';
import { ClassToggleService } from '@coreui/angular-pro';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [LoginService]
})
export class LoginComponent implements OnInit, AfterViewInit {

  public version = packagejson.version;

  public loading: boolean = false;
  
  //FORM GROUP
  public loginForm!: FormGroup;
  //FORM
  public emailControl!: AbstractControl;
  public senhaControl!: AbstractControl;

  constructor(
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    private validationService: ValidationService,
    private router: Router,
    private classToggler: ClassToggleService
  ) { }
  
  ngOnInit(): void {
    this.loginService.logout();
    this.buildForm();
  }

  ngAfterViewInit(): void {
    this.setTheme();
  }

  /**
     * Montagem do formulário
     * @memberof LoginComponent
     */
  buildForm(): void {
    this.loginForm = this.formBuilder.group({
      emailControl: ['', Validators.compose([Validators.required])],
      senhaControl: ['', Validators.compose([Validators.required])],
    });

    this.emailControl = this.loginForm.controls['emailControl'];
    this.senhaControl = this.loginForm.controls['senhaControl'];
  }

  /**
     * Tratamento de erros do form
     * @param {AbstractControl} control
     * @returns
     * @memberof LoginComponent
     */
  errorMessage(control: AbstractControl) {
    return this.validationService.errorMessage(control);
  }

  /**
     * Tratamento de erros do form
     * @param {AbstractControl} control
     * @returns
     * @memberof LoginComponent
     */
  onLogin(): void {
    this.loading = true;
    this.loginService.login(this.emailControl.value, this.senhaControl.value)
      .subscribe(
        () => {
          this.loading = false;
          this.router.navigate(['/']);
        }
      );
  }

  /**
     * 
     * @returns
     * @memberof LoginComponent
     */
  setTheme() {
    let body = document.getElementById("principal");

    if (body?.classList.contains("dark-theme")) {
      this.classToggler.toggle('body', 'dark-theme');
    }
  }
}
