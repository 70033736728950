import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ProgressDialogComponent } from './../progress-dialog.component';

@Injectable()
export class ProgressDialogService {

  public modalRef: any;

  public qntTotalSend: number = 0;

  constructor(private modalService: NgbModal) { }

  public uploadInfo(
    title: string,
    message: string,
    qntTotalSend: number = 0,
    icon: string = '',
    dialogSize: 'sm'|'md'|'lg' = 'lg',
    dialogCentered: true | false = true): Promise<boolean> {
    this.modalRef = this.modalService.open(ProgressDialogComponent, { size: dialogSize, centered: dialogCentered });
    this.modalRef.componentInstance.title = title;
    this.modalRef.componentInstance.message = message;
    this.modalRef.componentInstance.qntTotalSend = qntTotalSend;
    this.modalRef.componentInstance.icon = icon;
    this.qntTotalSend = qntTotalSend;
    
    return this.modalRef.result;
  }

  public updateProgress(qntSend: number) {
    let percentagem = ((qntSend / this.qntTotalSend) * 100).toFixed(2);
    this.modalRef.componentInstance.qntSendNow = qntSend;
    this.modalRef.componentInstance.progressValueNow = percentagem;

    if (qntSend == this.qntTotalSend) {
      this.modalRef.componentInstance.activeModal.close()
    }
  }


}
